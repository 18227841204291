<template>
    <!--新增对话框-->
    <el-dialog :title="editForm.insuranceTypeId ? '编辑保险费用' : '新增保险费用'" :visible.sync="dialogVisible" width="600px"
        :close-on-click-modal="false" :before-close="handleClose">
        <el-form :model="editForm" :rules="editFormRules" ref="editForm">
            <el-form-item label="保值(万元)" prop="insuredValue" label-width="100px">
                <el-input v-model="editForm.insuredValue" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="保费(元)" prop="insurancePremium" label-width="100px">
                <el-input v-model="editForm.insurancePremium" autocomplete="off"></el-input>
            </el-form-item>
            <!-- <el-form-item label="系统保费(元)" prop="sysInsurancePremium" label-width="100px">
                <el-input v-model="editForm.sysInsurancePremium" autocomplete="off"></el-input>
            </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm('editForm')">取 消</el-button>
            <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            editForm: {  },
            dialogVisible: false,
            editFormRules: {
                insuredValue: [
                    { required: true, message: '请输入保值', trigger: 'blur' }
                ],
                insurancePremium: [
                    { required: true, message: '请输入保费', trigger: 'blur' }
                ],
            },
        }
    },

    methods: {
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.editForm = {}
        },
        handleClose() {
            this.resetForm('editForm')
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.editForm.sysInsurancePremium=this.editForm.insurancePremium;
                    this.$axios.post('/admin/insurancetype/' + (this.editForm.insuranceTypeId ? 'updateInsuranceType' : 'saveInsuranceType'), this.editForm)
                        .then(res => {
                            this.$emit('refreshDataList')
                            this.$message({
                                showClose: true,
                                message: '恭喜你，操作成功',
                                type: 'success',
                                onClose: () => { }
                            });
                            this.dialogVisible = false
                            this.resetForm(formName)
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        init(insuranceTypeId) {
            if (insuranceTypeId != null && insuranceTypeId != '') {
                this.$axios.post('/admin/insurancetype/getInsuranceTypeInfo', { insuranceTypeId: insuranceTypeId }).then(res => {
                    this.editForm = res.data
                })
            }
            this.dialogVisible = true;
        },

    }
}
</script>

<style scoped></style>
